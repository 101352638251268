<template>
    <v-container>
        <v-row dense>
            <v-col v-for="( item, i) in reportsByRol" :key="i" cols="12" md="6" sm="6">
                <v-card :color="item.color" dark @click="item.onClick">
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="headline font-weight-bold text-h5" v-text="item.titulo">
                            </v-card-title>
                            <v-card-subtitle v-text="item.subtitulo">
                            </v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-3" fab icon height="20px"  right width="40px">
                                    <v-icon class="mr-2" large>fas fa-file-excel</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <v-avatar class="ma-3" size="125" tile>
                            <v-icon x-large>{{item.icon}}</v-icon>
                        </v-avatar>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <ExcelExportVenta :dialog.sync="dialogExcelExport" :handleModal="handleExcelExport" 
        :tipo-salida.sync="tipoSalida" :sucursales.sync="sucursales" :is-admin="isAdmin"
        v-if="dialogExcelExport"/>
        <ExcelExportSellDebts :dialog.sync="dialogExcelSellDebtExport" :handleModal="handleExcelSellDebtExport"
        :sucursales.sync="sucursales" :clients.sync="clients"
        :is-admin="isAdmin" v-if="dialogExcelSellDebtExport"/>

        <ExcelExportEntryDetail :dialog.sync="dialogExcelEntryDetailExport" :handleModal="handleExcelEntryDetailExport"
        :typeEntry.sync="tipoEntrada" :providers.sync="proveedores"
        :brands.sync="sucursales" :is-admin="isAdmin" v-if="dialogExcelEntryDetailExport"/>
        <ExcelExportAsistencia :dialog.sync="dialogExcelAssistant" :handleModal="handleExcelAssistant" v-if="dialogExcelAssistant"/>

        <ExcelExportSellProfit :dialog.sync="dialogExcelSellProfitExport" :handleModal="handleExcelSellProfitExport"
        :sucursales.sync="sucursales" :is-admin="isAdmin" v-if="dialogExcelSellProfitExport"/>
    </v-container>

</template>
<script>
import TipoSalidaDataService from '@/service/venta/TipoSalidaDataService';
import ExcelExportVenta from '../venta/ExcelExportVenta.vue';
import ExcelExportSellDebts from '../venta/ExcelExportSellDebts.vue';
import ExcelExportEntryDetail from '../entrada/ExcelExportEntryDetail.vue';
import tipoPagoDataService from '@/service/catalogo/tipoPago/tipoPagoDataService';
import sucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import usuarioDataService from '@/service/usuario/usuarioDataService';
import TipoEntradaService from '@/service/entrada/TipoEntradaService';
import ProveedorDataService from '@/service/catalogo/proveedor/proveedorDataService';
import ExcelExportAsistencia from '../asistenciaPersonal/ExcelExportAsistencia.vue';
import ExcelExportSellProfit from '../venta/ExcelExportSellProfit.vue';
import clienteDataService from '@/service/cliente/clienteDataService';

export default {
    name: 'Reportes',
    components: {
        ExcelExportVenta, ExcelExportSellDebts, ExcelExportEntryDetail, ExcelExportAsistencia, ExcelExportSellProfit
    },
    created() {
        this.getAllTipoSalida();
        this.getAllTipoPago();
        this.getAllSucursal();
        this.getAllTipoEntrada();
        this.getProveedores();
        this.getClients();
    },
    data() {
        return {
            tipoSalida: [],
            tipoPagos: [],
            sucursales: [],
            dialogExcelExport: false,
            dialogExcelSellDebtExport: false,

            dialogExcelEntryDetailExport: false,
            tipoEntrada: [],
            proveedores: [],
            clients: [],

            dialogExcelAssistant: false,
            dialogExcelSellProfitExport: false,
            reportes: [
                {  id: 1,
                    color: 'indigo darken-1', titulo: 'Reporte de entradas',
                    subtitulo: 'Ingresos granja san Fernando',
                    icon: 'fas fa-exchange-alt',
                    onClick: () => this.handleExcelEntryDetailExport()
                },
                {   id: 2,
                    color: 'green darken-3', titulo: 'Reporte de ventas',
                    subtitulo: 'Ventas san Fernando',
                    icon: 'fas fa-file-invoice-dollar',
                    onClick: () => this.handleExcelExport()
                },
                {   id: 3,
                    color: 'deep-purple lighten-1', titulo : 'Reporte de productividad',
                    subtitulo: 'Productividad san Fernando',
                    icon: 'fas fa-chart-line',
                    onClick: () => this.handleExcelSellProfitExport()
                },
                {   id: 4,
                    color: 'red darken-1', titulo: 'Reporte de cobranza',
                    subtitulo: 'Cobranza san Fernando',
                    icon: 'fas fa-money-check-alt',
                    onClick: () => this.handleExcelSellDebtExport()
                },
                {   id: 5,
                    color: 'blue darken-1', titulo: 'Reporte de asistencia',
                    subtitulo: 'Asistencia san Fernando',
                    icon: 'fas fa-user-clock',
                    onClick: () => this.handleExcelAssistant()
                }
            ],
        }
    },
    methods: {
        handleExcelExport() { this.dialogExcelExport = !this.dialogExcelExport },
        handleExcelSellDebtExport() { this.dialogExcelSellDebtExport = !this.dialogExcelSellDebtExport },
        handleExcelEntryDetailExport() { this.dialogExcelEntryDetailExport = !this.dialogExcelEntryDetailExport },
        handleExcelAssistant() { this.dialogExcelAssistant = !this.dialogExcelAssistant },
        handleExcelSellProfitExport() { this.dialogExcelSellProfitExport = !this.dialogExcelSellProfitExport },
        async getAllTipoSalida(){ this.tipoSalida = await TipoSalidaDataService.getAllTipoSalida().then( resp => resp.data ) || [] },
        async getAllTipoPago(){ this.tipoPagos = await tipoPagoDataService.allTipoPago().then( rsp => rsp.data ) || []},
        async getAllSucursal(){ this.sucursales = await sucursalDataService.lstSucursal().then( resp => resp.data ) || [] },
        async getAllTipoEntrada(){ this.tipoEntrada = await TipoEntradaService.getAllTipoEntrada().then( rsp => rsp.data ) || [];},
        async getProveedores(){ this.proveedores = await ProveedorDataService.allProveedor().then( rsp => rsp.data ) || []; },
        async getClients(){ this.clients = await clienteDataService.getAll().then( rsp => rsp.data ) || []; }
    },
    computed: {
        roles(){
            return usuarioDataService.getUserRoles();
        },
        isAdmin(){ return this.roles.includes('ROLE_ADMIN'); },
        reportsByRol(){
            const rolesNotAllowed = ['ROLE_USER', 'ROLE_ADMINISTRATOR_1', 'ROLE_ADMINISTRATOR_2'];
            const [userRole] = this.roles;
            const hasPermission = !rolesNotAllowed.includes(userRole);

            return this.reportes.filter(report => hasPermission || report.id !== 3);
        }
    }
}
</script>