<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal">
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-toolbar-title>Generar reporte de productividad</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
                    </v-col>
                    <v-col cols="6">
                        <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate"
                            :min-date="startDate" />
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" v-model="sucursalId"
                            label="Sucursales" required outlined prepend-inner-icon="fas fa-id-card-alt"
                            :readonly="!isAdmin" hint="Seleccione la sucursal. Este campo es opcional" clearable>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click.native="downloadExcelSellProfit">Descargar reporte</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import VentaDetalleLoteDataService from '@/service/venta/VentaDetalleLoteDataService';
import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';
export default {
    name: 'ExcelExportSellProfit',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        sucursales: { type: Array },
        isAdmin: { type: Boolean, default: false }
    },
    components: { SingleCalendar },
    data() {
        return {
            startDate: getToday().toFormat('yyyy-MM-dd'),
            endDate: getToday().toFormat('yyyy-MM-dd'),
            sucursalId: SucursalDataService.getCurrentSucursalId()
        }
    },
    methods: {
        downloadExcelSellProfit() {
            const startDate = getDateLocaleNatural(this.startDate) ?? '';
            const endDate = getDateLocaleNatural(this.endDate) ?? '';
            const brandName = this.sucursales.find( sucursal => sucursal.id == this.sucursalId )?.nombre ?? '';
            this.$swal.fire({
                title: 'Generando reporte de productividad',
                text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const params = { startDate, endDate, sucursalId: this.sucursalId };
                    try {
                        return await VentaDetalleLoteDataService.downloadSellProfitExcel( params );
                    } catch (error) {
                        this.$swal.showValidationMessage(`Petición fallida: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then( result => {
                if (result.isConfirmed) {
                    if( result.value.status == 204 ) {
                      this.$swal.fire({ icon: 'info', title: 'Reporte de productividad', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                    } else {
                      const url = window.URL.createObjectURL(new Blob([result.value.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', `Ventas_productividad_${brandName}_${startDate}_${endDate}.xlsx`);
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                      this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Reporte descargado', showConfirmButton: false, timer: 1500 });
                    }
                }
            })        
        }
    }
}
</script>
