<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal">
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-toolbar-title>Generar reporte de cobranza en excel</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
                    </v-col>
                    <v-col cols="6">
                        <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate"
                            :min-date="startDate" />
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" v-model="sucursalId"
                            label="Sucursales" required outlined prepend-inner-icon="fas fa-id-card-alt"
                            :readonly="!isAdmin" hint="Seleccione la sucursal. Este campo es opcional" clearable>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="clients" item-text="nombreComercial" item-value="idCliente" v-model="clientId"
                            label="Clientes" required outlined prepend-inner-icon="fas fa-user-alt"
                            :readonly="!isAdmin" hint="Seleccione un cliente. Este campo es opcional" clearable>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click.native="downloadExcelSellDebts">Descargar reporte</v-btn>
                <v-btn color="blue darken-4" dark class="btn text-white" @click="previewPDF">
                  <v-icon left>fas fa-file-pdf</v-icon>Ver PDF
                </v-btn>
            </v-card-actions>
        </v-card>
        <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
      title-pdf="REPORTE DE COBRANZA" :handlePdfModal="handlePdfModal"
      :dialog="showPdf" v-if="showPdf">
    </PreviewPDF>
    </v-dialog>
</template>

<script>
import PreviewPDF from '@/components/shared/PreviewPDF.vue';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import VentaDataService from '@/service/venta/VentaDataService';
import { getDateLocaleNatural } from '@/utils/DateUtil';

export default {
  name: 'ExcelExportSellDebts',
  props: {
    dialog: { type: Boolean, default: false },
    handleModal: { type: Function },
    sucursales: { type: Array },
    clients: { type: Array },
    isAdmin: { type: Boolean, default: false }
  },
  components: { SingleCalendar, PreviewPDF },
  data() {
    return {
      startDate: '',
      endDate: '',
      sucursalId: SucursalDataService.getCurrentSucursalId(),
      clientId: null,
      // to PDF
      pdfBlob: '',
      pdfSource: null,
      showPdf: false,
    }
  },
  methods: {
    downloadExcelSellDebts() {
        const startDate = getDateLocaleNatural(this.startDate) ?? '';
        const endDate = getDateLocaleNatural(this.endDate) ?? '';
        const brandName = this.sucursales.find(brand => brand.id === this.sucursalId)?.nombre ?? '';
        this.$swal.fire({
          title: 'Quieres continuar con la descarga del reporte de cobranza?',
          text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const params = { startDate, endDate, sucursalId: this.sucursalId, clientId: this.clientId ?? 0 };
            try {
              return await VentaDataService.downloadSellDebts(params);
            } catch (error) {
              this.$swal.showValidationMessage(`Petición fallida: ${error}`);
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then( result => {
          if (result.isConfirmed) {
            if( result.value.status == 204 ) {
              this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
            } else {
              const url = window.URL.createObjectURL(new Blob([result.value.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Cobranza_${brandName}_${startDate}_${endDate}.xlsx`);
              document.body.appendChild(link);
              link.click();
              link.remove();
              this.$swal.fire({ icon: 'success', title: 'Reporte de cobranza', text: 'Reporte descargado', showConfirmButton: false, timer: 1500 });
            }
          }
        }).catch(err => {
          this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err, showConfirmButton: false, timer: 3500 });
        })
    },
    previewPDF() {
      const startDate = getDateLocaleNatural(this.startDate) ?? '';
      const endDate = getDateLocaleNatural(this.endDate) ?? '';
      const brandName = this.sucursales.find(brand => brand.id === this.sucursalId)?.nombre ?? '';
      const params = { startDate, endDate, sucursalId: this.sucursalId, clientId: this.clientId ?? 0 };
      VentaDataService.printSellDebts(params).then( response => {
        if(response.status == 204) {
          this.$swal.fire({ icon: 'info', title: 'Reporte PDF Cobranza', text: 'No hay registros para visualizar', showConfirmButton: false, timer: 2500 });
          return
        }
        this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
        this.pdfSource = URL.createObjectURL(this.pdfBlob)
        this.showPdf = true
      }).catch( error => {
        this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al generar el reporte' + error.response.data, showConfirmButton: false, timer: 3500 });
      });
    },
    handlePdfModal(){
      this.showPdf = !this.showPdf
      this.cleanupPdf()
    },
    cleanupPdf(){
      if(this.pdfSource){
        URL.revokeObjectURL(this.pdfSource)
        this.pdfSource = null
      }
      this.pdfBlob = null
    }
  }
}
</script>